import { environments } from './environments'
import degenArcadeABI from '../ABIs/degenArcadeABI.json'
import ERC721_ABI from '../ABIs/ERC721_ABI.json'
import ERC1155_ABI from '../ABIs/ERC1155_ABI.json'

const defaultConfig = {
  // Application
  env: process.env.NODE_ENV || environments.development,
  blockchain: process.env.NEXT_PUBLIC_CHAIN || 'mumbai',

  // Services
  mongoDBUri: process.env.MONGODB_URI || 'mongodb://localhost/degenarcade',

  // Chain
  chainId: process.env.NEXT_PUBLIC_CHAIN === 'ethereum' ? 1 : 80001,

  // Magic Link
  magicLinkApiKey: process.env.NEXT_PUBLIC_MAGIC_LINK_API_KEY || '',
  magicLinkRpcUrl: process.env.NEXT_PUBLIC_MAGIC_LINK_RPC_URL || 'https://polygon-rpc.com',
  magicLinkChainId: process.env.NEXT_PUBLIC_MAGIC_LINK_CHAIN_ID || '137',

  //ABIs
  degenArcadeABI,
  ERC721_ABI,
  ERC1155_ABI,

  //Addresses
  degenArcadeAddress: process.env.NEXT_PUBLIC_DEGEN_ARCADE_ADDRESS,

  // Alchemy
  alchemyApiKey: process.env.ALCHEMY_API_KEY,

  //Pinata
  pinataGatewayToken: process.env.NEXT_PUBLIC_PINATA_GATEWAY_TOKEN,

  // Service token (used to validate machine session)
  serviceToken: process.env.SERVICE_TOKEN,

  //Stripe
  stripeSecreteKey: process.env.STRIPE_SECRET_KEY,
  stripeEndpointSecret: process.env.STRIPE_ENDPOINT_SECRET,
  stripePublishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,

  // Crypto payment
  ethConverterApi: process.env.NEXT_PUBLIC_API_COINBASE || 'https://api.coinbase.com/v2/prices/eth-usd/spot/',
  transactionScanUrl:
    process.env.NEXT_PUBLIC_CHAIN === 'ethereum' ? 'https://etherscan.io/tx/' : 'https://mumbai.polygonscan.com/tx/',
  nftUrl:
    process.env.NEXT_PUBLIC_CHAIN === 'ethereum'
      ? 'https://opensea.io/assets/ethereum/'
      : 'https://testnets.opensea.io/assets/mumbai/',

  // OZ Relay
  ozRelayApiKey: process.env.OZ_API_RELAY_KEY,
  ozRelaySecretKey: process.env.OZ_RELAY_SECRET_KEY,

  // Cookies
  cookies: {
    cookieName: process.env.COOKIE_NAME || 'degenarcade_user',
    password: process.env.COOKIE_PASSWORD || '@1&2@xk0De3MTxuzI&Dps7rHI#eiqJYX',
    cookieOptions: {
      secure: process.env.NODE_ENV === 'production' || !environments.development,
    },
  },

  // Prizes
  maximumPrizeRetries: 10,

  // S3
  s3: {
    region: process.env.S3_REGION,
    accessKeyId: process.env.S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.S3_SECRET_ACCESS_KEY,
    bucketName: process.env.S3_BUCKET_NAME,
    urlExpiresAtInMS: process.env.S3_URL_EXPIRES_AT_IN_MS || 86400,
  },
}

export const config: typeof defaultConfig = defaultConfig
