import '../styles/tailwind.css'
import '../styles/carouselStyles.scss'
import type { AppProps } from 'next/app'
import { UserProvider } from '@auth0/nextjs-auth0/client'
import { ReactElement, ReactNode } from 'react'
import { NextPage } from 'next'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { polygonMumbai, mainnet } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { config } from '@config/config'

const { chains, provider, webSocketProvider } = configureChains(
  [config.chainId === 1 ? mainnet : polygonMumbai],
  [publicProvider()],
)

const client = createClient({
  autoConnect: false,
  connectors: [new MetaMaskConnector({ chains })],
  provider,
  webSocketProvider,
})

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  if (Component.getLayout) {
    return (Component as any).getLayout(
      <WagmiConfig client={client}>
        <UserProvider>
          <Component {...pageProps} />
        </UserProvider>
      </WagmiConfig>,
    )
  }

  return (
    <UserProvider>
      <Component {...pageProps} />
    </UserProvider>
  )
}
